import { useCallback, useEffect, RefObject } from 'react';

type UseOffClickTypes = {
  engaged: boolean;
  callback: () => void;
  container: RefObject<HTMLElement>;
};

const useOffClick = ({ engaged, callback, container }: UseOffClickTypes): void => {
  const handleDocumentClick = useCallback(
    e => {
      if (container && container.current) {
        // Click is within the container's own content. No need to dismiss.
        if (container.current.contains(e.target)) {
          return;
        }

        // If the useOffClick hook was registered on a container, then a modal appeared,
        // the modal clicks should be ignored. The modal has no bearing on the container
        // being dismissed or not. For example, if a popover had a button that then
        // triggered a fullscreen modal, closing that modal should not also close the popover.
        const clickInModal = e.target.closest('#modalBackground');
        const containerInModal = container.current.closest('#modalBackground');
        if (clickInModal && !containerInModal) {
          return;
        }

        // Same thing with the alert dialog :)
        const clickInAlertDialog = e.target.closest('#alertDialog');
        const containerInAlertDialog = container.current.closest('#alertDialog');
        if (clickInAlertDialog && !containerInAlertDialog) {
          return;
        }
      }

      callback();
    },
    [callback, container]
  );

  useEffect(() => {
    if (engaged) {
      document.addEventListener('click', handleDocumentClick, true);
    } else {
      document.removeEventListener('click', handleDocumentClick, true);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick, true);
    };
  }, [engaged, handleDocumentClick]);
};

export default useOffClick;
