import classNames from 'classnames';
import { CSSProperties } from 'react';
import styles from './styles/styles.module.scss';

type ProgressBarStepProps = {
  animationMs?: number;
  ariaLabel: string;
  complete: boolean;
  onClick?: () => void;
};

const ProgressBarStep = ({ animationMs, ariaLabel, complete, onClick }: ProgressBarStepProps) => {
  const step = (
    <div className={styles.step}>
      <div className={styles.stepEmpty} />
      <div
        className={classNames(styles.stepProgress, { [styles.complete]: complete, [styles.animating]: animationMs })}
        style={{ '--animation-duration': `${animationMs}ms` } as CSSProperties}
      />
    </div>
  );
  return onClick ? (
    <button aria-label={ariaLabel} className={styles.stepContainer} onClick={onClick}>
      {step}
    </button>
  ) : (
    <div aria-label={ariaLabel} className={styles.stepContainer}>
      {step}
    </div>
  );
};

export default ProgressBarStep;
