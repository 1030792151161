import classNames from 'classnames';
import { defineMessage, useIntl } from 'react-intl';
import styles from './styles/styles.module.scss';
import ProgressBarStep from './ProgressBarStep';

type PagedProgressBarProps = {
  className?: string;
  index: number;
  total: number;
  onClick?: (index: number) => void;
};

const PagedProgressBar = ({ className, index, total, onClick }: PagedProgressBarProps) => {
  const intl = useIntl();
  const stepButtonLabel = defineMessage({
    defaultMessage: 'Option {optionNumber}'
  });

  return (
    <div className={classNames(styles.progressBar, className)}>
      {Array(total)
        .fill('')
        .map((_, i) => {
          return (
            <ProgressBarStep
              key={`step-${i}`}
              ariaLabel={intl.formatMessage(stepButtonLabel, { optionNumber: i + 1 })}
              complete={i <= index}
              onClick={onClick ? () => onClick(i) : undefined}
            />
          );
        })}
    </div>
  );
};

export default PagedProgressBar;
