import { useEffect } from 'react';

const useScreenLock = (isOpen: boolean) => {
  useEffect(() => {
    const htmlDocument = document.querySelector('html') as HTMLElement;

    if (isOpen) {
      htmlDocument.classList.add('modalOpen');
    }

    return () => {
      const count = document.getElementById('modalPortal')?.childElementCount;
      if (count == undefined || count < 1) {
        // Remove the class only if there are no more modals
        htmlDocument.classList.remove('modalOpen');
      }
    };
  }, [isOpen]);
};

export default useScreenLock;
