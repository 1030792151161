import { ComponentProps } from 'react';
import Button from '@alltrails/denali/components/Button';
import styles from './styles/styles.module.scss';

type ButtonDefinition = Pick<
  ComponentProps<typeof Button>,
  'icon' | 'linkInfo' | 'loading' | 'onClick' | 'testId' | 'text' | 'disabled' | 'className'
>;

type FooterButtonsProps = {
  fill?: boolean;
  defaultButton?: ButtonDefinition;
  flatButton?: ButtonDefinition & { placement?: 'left' | 'right' };
  primaryButton: ButtonDefinition;
};

const FooterButtons = ({ fill, defaultButton, flatButton, primaryButton }: FooterButtonsProps): JSX.Element | null => {
  return fill ? (
    <div className={styles.fillContainer}>
      {defaultButton && <Button {...defaultButton} variant="default" width="full" />}
      {flatButton && <Button {...flatButton} variant="flat" width="full" />}
      {primaryButton && <Button {...primaryButton} variant="primary" width="full" />}
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.leftButtons}>
        {defaultButton && <Button {...defaultButton} variant="default" width="auto" />}
        {flatButton && flatButton.placement === 'left' && <Button {...flatButton} variant="flat" width="auto" />}
      </div>
      <div className={styles.rightButtons}>
        {flatButton && (!flatButton.placement || flatButton.placement === 'right') && <Button {...flatButton} variant="flat" width="auto" />}
        {primaryButton && <Button {...primaryButton} variant="primary" width="auto" />}
      </div>
    </div>
  );
};

export default FooterButtons;
