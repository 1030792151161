import { cloneElement, useState, useMemo, useRef } from 'react';
import { usePopper } from 'react-popper';
import type { VariationPlacement } from '@popperjs/core';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react'; // If FocusTrap is used for other "full-screen" UIs, consider splitting the overlay + FocusTrap into its own component
import { useAnimateInOutComponent, useGroupHover, useOffClick } from '@alltrails/core';
import { SPACE_4 } from '../../tokens';
import styles from './styles/styles.module.scss';

type PopoverProps = {
  anchor: JSX.Element;
  children: React.ReactNode;
  className?: string;
  containerMouseHandlers?: {
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
  };
  placement?: Extract<VariationPlacement, 'bottom-start' | 'bottom-end' | 'top-start' | 'top-end'>;
  isOpen?: boolean;
  onCloseRequest: () => void;
  returnFocusOnDeactivate?: boolean;
  testId?: string;
  isMobile?: boolean;
};

const Popover = ({
  anchor,
  children,
  className,
  containerMouseHandlers,
  placement = 'bottom-start',
  isOpen = false,
  onCloseRequest,
  testId,
  isMobile = false
}: PopoverProps): JSX.Element => {
  const { isAnimating, isVisible, onAnimationEnd } = useAnimateInOutComponent(isOpen);
  const containerRef = useRef<HTMLDivElement>(null);

  // Popper needs the DOM node, not a ref
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(anchorElement, popperElement, {
    placement,
    modifiers: [
      // This can be slightly more expensive on re-computing positions but may
      // also avoid edge-case layout issues.
      { name: 'computeStyles', options: { adaptive: false } },
      { name: 'offset', options: { offset: [0, SPACE_4] } }
    ]
  });

  useOffClick({ engaged: isOpen, callback: onCloseRequest, container: containerRef });

  const anchorEl = useMemo(() => cloneElement(anchor, { ref: setAnchorElement }), [anchor]);

  // useGroupHover, along with a little css, is needed to avoid an issue where hovering in between the anchor and the popover can cause
  // the popover to repeatedly open and close since onMouseEnter is triggered as the popover animates out.
  const { onMouseEnter, onMouseLeave } = useGroupHover(containerMouseHandlers?.onMouseEnter, containerMouseHandlers?.onMouseLeave);

  return (
    <div ref={containerRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {anchorEl}
      {(isAnimating || isVisible || isMobile) && (
        <FocusTrap
          active={isVisible}
          // don't use clickOutsideDeactivates because it can lead to weird issues with clicking on the anchor while the popover is open
          // fallbackFocus is used to prevent errors in testing https://github.com/focus-trap/focus-trap-react/issues/91
          focusTrapOptions={{
            allowOutsideClick: true,
            clickOutsideDeactivates: false,
            escapeDeactivates: true,
            onDeactivate: onCloseRequest,
            fallbackFocus: '#popover',
            initialFocus: false
          }}
        >
          <div
            ref={setPopperElement}
            className={classNames(
              styles.popper,
              styles[placement.split('-')[0]],
              {
                [styles.isAnimating]: isAnimating,
                [styles.isVisible]: isVisible
              },
              className
            )}
            data-testid={testId}
            id="popover"
            onAnimationEnd={onAnimationEnd}
            style={popperStyles.popper}
            {...popperAttributes.popper}
          >
            {children}
          </div>
        </FocusTrap>
      )}
    </div>
  );
};

export default Popover;
