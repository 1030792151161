import { useCallback, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';

/**
 * This hook is useful when you want to track mouse hover events for component as a whole.
 * When the mouse switches from A to B, the leave of A triggers before the enter of B.
 * So, by merging both of these events into a single debounced function, we can essentially ignore
 * the change of hover, assuming both A and B are using the returned enter and leave functions.
 *
 * @param onHoverStart memoized function to be called on mouse enter
 * @param onHoverEnd memoized function to be called on mouse leave
 * @param isMouseMove whether the returned onMouseEnter will be used in a mousemove event, in which case onHoverStart will be called regardless of the previous hover state
 * @param debounceTime time in ms used in the debounce function, defaults to 100
 * @returns memoized object of enter and leave functions to be used instead of the passed in functions
 */
const useGroupHover = (onHoverStart?: (...args: any[]) => void, onHoverEnd?: (...args: any[]) => void, isMouseMove = false, debounceTime = 100) => {
  const isHovered = useRef(false);

  const handleHoverChange = useCallback(
    (action: 'enter' | 'leave', ...args: any[]) => {
      if (action === 'leave' && onHoverEnd) {
        onHoverEnd(...args);
      } else if (action === 'enter' && onHoverStart && (!isHovered.current || isMouseMove)) {
        onHoverStart(...args);
      }
      isHovered.current = action === 'enter';
    },
    [isMouseMove, onHoverEnd, onHoverStart]
  );
  const debouncedHandleHoverChange = useMemo(() => debounce(handleHoverChange, debounceTime), [handleHoverChange]);

  const onMouseEnter = useMemo(
    () =>
      (...args: any[]) =>
        debouncedHandleHoverChange('enter', ...args),
    [debouncedHandleHoverChange]
  );
  const onMouseLeave = useMemo(
    () =>
      (...args: any[]) =>
        debouncedHandleHoverChange('leave', ...args),
    [debouncedHandleHoverChange]
  );
  return useMemo(() => {
    if (!onHoverStart && !onHoverEnd) {
      return { onMouseEnter: undefined, onMouseLeave: undefined };
    }
    return { onMouseEnter, onMouseLeave };
  }, [onHoverEnd, onHoverStart, onMouseEnter, onMouseLeave]);
};

export default useGroupHover;
